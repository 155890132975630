<template>
  <div class="row mt-md-0 mt-5">

    <!-- Form -->
    <div class="col-md-12">
      <Card v-bind:title="'Edit Poliklinik'">
        <template
          v-slot:body
          v-if="dataLoaded"
        >
          <Form
            :form="form"
            :route="'polyclinics/' + form.id"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/polyclinics/Form.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: "",
        description: "",
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('polyclinics/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/polyclinic/list')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Poliklinik", route: "" },
      { title: "Daftar Poliklinik", route: "/polyclinic/list" },
      { title: "Edit" },
    ])
    // Get Data
    this.get()
  }

}

</script>